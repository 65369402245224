/* side nav bar */

.side-nav-bar {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* side nav menu */

.side-nav-menu-item,
.side-nav-user {
  margin: 8px 0px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: initial;
  cursor: pointer;
  overflow: hidden;
}

.active {
  background-color: rgb(129, 169, 188, 0.1);
  border-radius: 8px;
  color: #437285;
}

.side-nav-menu-item-icon {
  flex-shrink: 0;
  display: inline-flex;
  min-width: 0px;
  width: 22px;
  margin-right: 8px;
}

.side-nav-menu-item-label {
  flex: 1 1 auto;
  min-width: 0px;
  opacity: 1;
}

.side-nav-menu-item-label-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* side nav header */

.side-nav-header {
  top: 0;
  position: sticky;
  z-index: 99;
  height: 65px;
  width: 100%;
  background-color: #333333;
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
