.page-header {
  z-index: 100;
  display: flex;
  position: sticky;
  top: 0px;
  box-shadow: 2px 2px 8px 0px rgba(67, 114, 133, 0.1);
  background-color: #f5f5f5;
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
}
