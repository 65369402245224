.basketcard-info-item-counter {
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #444444;
  font-size: 14px;
}
.basketcard-info-name {
  align-self: stretch;
  flex: 1 0 0;
  min-height: 72px;
  word-break: break-word;
}

.basketcard-actions {
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  gap: 4px;
}

.basketItem:hover .basketcard-actions {
  background-color: rgba(242, 242, 242, 1);
}

.basketItem:hover .basketcard-info-item-counter {
  background-color: rgba(242, 242, 242, 1);
}
.basketcard-actions i:hover {
  color: black !important;
}
