.search-form {
  position: relative;
}

.search-form .fa-search {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #9ca3af;
}

.search-form-input {
  text-indent: 24px;
}
