.autocomplete-input {
  flex: content;
  border: none;
  outline: none;
  &:focus {
    border-color: none;
  }
}
.dropdown-menu {
  width: max-content;
  max-height: 33vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
}

.autocomplete-focus:focus-within {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
