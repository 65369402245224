@import url("bootstrap/dist/css/bootstrap.css");

/* Bootstrap overrides and global style */
.btn-secondary {
  background-color: #222;
}
.btn-secondary:hover {
  background-color: black;
}

.modal-content {
  background-color: #f2f2f2 !important;
}

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.text-right {
  text-align: right;
}

.form-group {
  margin-bottom: 12px;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 3rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-left: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 3rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.max-content {
  width: max-content;
}
.bg-resolved {
  background-color: #33ff57;
}
.bg-inprogress {
  background-color: #337aff;
}
.bg-onhold {
  background-color: #ffcb33;
}
.bg-assigned {
  background-color: #ff8c33;
}
.bg-unassigned {
  background-color: #b2b2b2;
}
.bg-closed {
  background-color: #ff3333;
}
.border-resolved {
  border-color: #33ff57 !important;
}
.border-inprogress {
  border-color: #337aff !important;
}
.border-onhold {
  border-color: #ffcb33 !important;
}
.border-assigned {
  border-color: #ff8c33 !important;
}
.border-unassigned {
  border-color: #b2b2b2 !important;
}
.border-closed {
  border-color: #ff3333 !important;
}
.border-info {
  border-color: #6295ac !important;
}
.bg-info {
  background-color: #6295ac !important;
}
.btn-danger {
  background-color: #f7e3e5;
  border: none;
  color: #ff3333;
}
.btn-danger:hover {
  color: #f7e3e5;
}

.form-range::-moz-range-track {
  background-color: #b2b2b2;
}

.btn-neutrallighter-washed {
  background-color: rgb(242, 242, 242, 0.2);
  border: none;
  color: #ffff;
}
.btn-neutrallighter-washed:hover {
  background-color: rgb(242, 242, 242, 0.1);
  border: none;
  color: #ffff;
}
.btn-secondary-washed {
  background-color: rgba(129, 169, 188, 0.1);
  border: none;
  color: rgba(67, 114, 133, 1);
  font-weight: 600;
}
.btn-secondary-washed:hover,
.btn-secondary-washed:focus,
.btn-secondary-washed:active:hover {
  background-color: rgba(129, 169, 188, 0.2);
  border: none;
  color: rgba(67, 114, 133, 1);
  font-weight: 600;
}
.btn-success-washed,
.btn-success-washed:hover,
.btn-success-washed:active,
.btn-success-washed:active:hover {
  background-color: rgba(0, 142, 42, 0.2);
  border: none;
  color: rgba(0, 142, 42, 1);
  font-weight: 600;
}
