.dropdown-toggle::after {
  display: none !important;
}

.apply > .dropdown-item {
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: #0d6efd;
  color: white;
}

.apply > .dropdown-item:hover {
  background-color: #0b5ed7;
  color: white;
}

.dropdown-indicator::after {
  display: block !important;
}
