.stat-number-card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: 1;
}
.stat-number-card label {
  border-radius: 4px;
  font-weight: bolder;
}

.stat-number-card-hover:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.33);
}
